import React from 'react';

export default function Intro() {
  return (
    <section className="intro-section" id="start">
      <div className="container-fluid content-section bg-grey">
        <div className="row">
          <div className='col-12 text-center'>
            <h2 className="font-size__xl mb-5 text-secondary">
              No solo hablamos de diseño
            </h2>
          </div>
          <div className="col-12 col-lg-6 block-padding__left">
            <p className='font-size__m'>
              Contar con un diseño atractivo y ofrecer una navegación intuitiva son factores clave para el mercado B2C, 
              pero no lo es todo. En Beköme vamos mucho más allá.
            </p>
            <p className='font-size__m'>
              Los gustos y las preferencias de tus clientes cambian a un ritmo vertiginoso y ofrecer un servicio que se 
              adapte a sus necesidades se convierte en el principal reto de cualquier eCommerce dirigido al consumidor final.
            </p>
            <p className='font-size__m'>
              En Beköme te proponemos soluciones que evolucionan con tus clientes y con el crecimiento de tu negocio. 
              Soluciones que ponen al usuario en el centro y te permiten dirigirte a él de manera personalizada. 
              En definitiva, que estimulan las ventas y fidelizan clientes.
            </p>
            <p className='font-size__m font-weight__700'>
              ¿Preparado para descubrir un nuevo concepto de Ecommerce?
            </p>
          </div>
          <div className="col-12 col-lg-6 bg-ecommerce-b2c block-padding__right">
           
          </div>
        </div>
      </div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-12 col-sm-6 content-section bg-primary order-0 py-lg-5">
            <h3 className="font-size__l text-center mb-5">
              ¿Cómo beneficia una solucion B2C a tu empresa?
            </h3>
            <div className="row">
              <div className="col-9 mx-auto">
                <p className='font-size__m'>
                  <ul className='mb-5 text-left list-unstyled text-secondary'>
                    <li><i class="fa-solid fa-check text-secondary"></i> Reducción del número de carritos abandonados</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Herramientas de marketing automatizadas</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Plataforma altamente escalable</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Potencia tu autoridad con reseñas de clientes</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Acceso a nuevos canales de venta a través de RRSS y marketplaces</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Integración de CRM para fidelizar al cliente</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 epo-bg order-1 pb-0 py-lg-5">
            <h3 className="font-size__l text-center text-primary mb-5">
            ¿Cómo beneficia una solucion B2C a tus clientes?
            </h3>
            <div className="row text-center">
              <div className="col-9 mx-auto">
                <p className="font-size__m">
                  <ul className='mb-5 text-left list-unstyled'>
                    <li><i class="fa-solid fa-check text-primary"></i> Procesos de compra rápidos y sin fricción</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Múltiples formas de pago</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Personalización de ofertas (retargeting)</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Compatible con dispositivos móviles</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Búsquedas y recomendaciones personalizadas</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Diferentes métodos de envío</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
