import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Contact from '../components/Contact';
import SEO from '../components/Seo';
import Intro from '../components/B2C/Intro';
import Projects from '../components/Proyectos';
import Calendly from '../components/Calendly';
import ScrollToTop from '../components/ScrollToTop';
import { navigate } from 'gatsby';

class SolucionesEcommerceB2B extends React.Component {
  render() {
    return (
      <LayoutEs>
        <Header />
        <SEO
          title="Soluciones Ecommerce B2C para retailers"
          description="Especialistas en tiendas online B2C. Descubre como Magento y BigCommerce pueden ayudar a tu negocio a ser más rentable y llegar a más público."
          canonicalUrl="https://www.bekome.digital/soluciones-ecommerce-b2c/"
        />

        <header className="masthead secondary ecommerce-b2b d-flex">
          <div className="container text-center my-auto">
            <h1 className="mb-1 text-primary">Soluciones B2C</h1>
            <h2 className="mb-4">
              adaptadas a los clientes de hoy
            </h2>
            <p className='h2 pl-5 pr-5'>
              Mejora la <span className="text-primary">experiencia de usuario</span> gracias a la personalización que ofrecen nuestras plataformas eCommerce B2C. 
            </p>
            <p className='h2 mb-5 pl-5 pr-5'>
              Porque el secreto del éxito se encuentra en <span className="text-primary">cómo haces sentir</span> a tus clientes.
            </p>
            <button className='btn btn-primary-green btn-lg' onClick={() => {navigate("/contacto/")}}>¿Hablamos?</button>
          </div>
          <div className="overlay"></div>
        </header>

        <Intro />

        <Projects />

        <Calendly />
        
        <div className='container-fluid bg-grey'>
          <h3 className="font-size__l text-secondary text-center pt-5">
            ¿Te gustaría conseguir todo esto con tu plataforma B2C?
          </h3>
          <h4 className='text-secondary text-center pb-4 mb-0'>Cuéntanos tus dudas y resolvámoslas juntos.</h4>
        </div>

        <Contact />

        <Footer />
        <ScrollToTop />
      </LayoutEs>
    );
  }
}

export default SolucionesEcommerceB2B;
